import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import CaretDownIcon from '@comp/Icons/CaretDownIcon';
import s from './Select.scss';

const cx = classNames.bind(s);

const getSelectOptionElements = ({ value, optionLabel }, index) => (
  <option key={index} value={value}>
    {optionLabel}
  </option>
);

const Select = ({
  options,
  input,
  id,
  expand,
  selectClassName,
  iconClassName
}) => {
  const optionItems = options.map(getSelectOptionElements);

  return (
    <div
      className={cx({
        container: true,
        container__expand: expand
      })}
    >
      <select
        id={id}
        className={cx({
          input: true,
          input__expand: expand,
          [selectClassName]: selectClassName
        })}
        {...input}
      >
        {optionItems}
      </select>
      <CaretDownIcon
        className={cx({
          caretDownIcon: true,
          [iconClassName]: iconClassName
        })}
      />
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  input: PropTypes.shape({}),
  expand: PropTypes.bool,
  id: PropTypes.string,
  iconClassName: PropTypes.string,
  selectClassName: PropTypes.string
};

export default Select;
