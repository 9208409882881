import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import s from './Input.scss';

const cx = classNames.bind(s);

const Input = ({
  id,
  name,
  type,
  expand,
  inputClassName,
  input = {},
  disabled,
  meta,
  onBlurCustom,
  autoComplete,
  step,
  fieldProps = {}
}) => (
  <input
    id={id}
    name={name}
    type={type}
    className={cx({
      input: true,
      input__expand: expand,
      input__error: meta && !!meta.error,
      [inputClassName]: inputClassName
    })}
    autoComplete={autoComplete}
    step={step}
    disabled={disabled}
    {...input}
    {...fieldProps}
    onBlur={
      onBlurCustom
        ? () => input.onBlur(onBlurCustom(input.value, input.name))
        : input.onBlur
    }
  />
);

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  expand: PropTypes.bool,
  inputClassName: PropTypes.string,
  input: PropTypes.object,
  fieldProps: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.string
  }),
  onBlurCustom: PropTypes.func,
  autoComplete: PropTypes.string,
  step: PropTypes.string
};

export default Input;
